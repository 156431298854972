import React, { useEffect, useState } from "react";
import "./Admin.css";
import { useSelector, useDispatch } from "react-redux";
import { firestore, auth } from "../../firebase";
import { v4 as uuidv4 } from "uuid";
import { getBookings } from "../../redux/slices/roomSlice";
import { useTranslation } from "react-i18next";
import { FaBan, FaCheckCircle, FaInfoCircle } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Table from 'react-bootstrap/Table';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import Alert from "react-bootstrap/Alert";
import { formatDate } from "../../helper";
import { sendMail } from "../../services/mail";

const Admin = () => {
    const { i18n, t } = useTranslation();
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [bookingToDelete, setBookingToDelete] = useState("");
    const [bookingToApprouve, setBookingToApprouve] = useState("");
    const [roomAddBooking, setRoomAddBooking] = useState("");
    const dispatch = useDispatch();
    const roomState = useSelector(state => state.room);
    const selectBooking = (booking) => {
        firestore.collection("Users").doc(booking.user).get().then(data => {
            let bookingClone = { ...booking };
            bookingClone.user = data.data();
            setSelectedBooking(bookingClone);
        });
    }
    const setStartDateTime = (date) => {
        const clone = new Date(date);
        const startDate = clone.setHours(14, 0, 0, 0);
        return new Date(startDate);
    };
    const setEndDateTime = (date) => {
        const clone = new Date(date);
        const endDate = clone.setHours(12, 0, 0, 0);
        return new Date(endDate);
    };
    const [startDate, setStartDate] = useState(setStartDateTime(new Date()));
    const [endDate, setEndDate] = useState(setEndDateTime(new Date().setDate(new Date().getDate() + 1)));
    const [available, setAvailable] = useState(true);
    const [error, setError] = useState("");

    const selectStartDate = (start) => {
        setAvailable(false);
        const startDate = setStartDateTime(start);
        if (startDate < new Date()) {
            setError(t("BOOKING_START_DATE_ERROR"));
        } else {
            setError("");
            setStartDate(startDate);
            checkAvailability(startDate, endDate);
        }
    };
    const selectEndDate = (end) => {
        setAvailable(false);
        const endDate = setEndDateTime(end);
        if (endDate < startDate) {
            setError(
                t("BOOKING_END_DATE_ERROR")
            );
        } else {
            setError("");
            setEndDate(endDate);
            checkAvailability(startDate, endDate);
        }
    };

    const addRoomBooking = () => {
        setError("");
        firestore
            .collection("Bookings")
            .doc(uuidv4())
            .set({
                room: roomAddBooking,
                startDate: startDate,
                endDate: endDate,
                user: auth.currentUser.email,
                status: "blocked",
                price: 0,
            })
            .then(() => {
            })
            .catch(() => {
                setError(t("GLOBAL_ERROR"));
            });
    }

    const checkAvailability = (startDate, endDate) => {
        firestore
            .collection("Bookings")
            .where("room", "==", roomAddBooking)
            .onSnapshot((result) => {
                setAvailable(true);
                setError("");
                result.forEach((item) => {
                    let booking = item.data();
                    if (startDate <= booking.endDate.toDate()) {
                        setAvailable(false);
                        setError(t("BOOKING_DATE_ERROR"));
                    }
                });
            });
    };

    useEffect(() => {
        dispatch(getBookings());
    }, []);
    return (
        <main role="main" className="flex-shrink-0">
            <div className="container">
                <Modal show={bookingToDelete !== ""} onHide={() => setBookingToDelete("")}>
                    <Modal.Body>
                        <p>{t("DASHBOARD_BOOKING_DELETE")}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setBookingToDelete("");
                            }}
                        >
                            {t("GLOBAL_CLOSE")}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                firestore.collection("Bookings").doc(bookingToDelete).get().then(data => {
                                    var booking = data.data();
                                    firestore.collection("Bookings").doc(bookingToDelete).delete().then(() => {
                                        sendMail(booking.room, booking.startDate.toDate(), booking.endDate.toDate(), booking.price, "booking-reject", i18n.language);
                                    });
                                    setBookingToDelete("");
                                });
                            }}
                        >
                            {t("GLOBAL_CONFIRM")}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={bookingToApprouve !== ""} onHide={() => setBookingToApprouve("")}>
                    <Modal.Body>
                        <p>{t("DASHBOARD_BOOKING_APPROUVE")}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setBookingToApprouve("");
                            }}
                        >
                            {t("GLOBAL_CLOSE")}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                firestore.collection("Bookings").doc(bookingToApprouve).get().then(data => {
                                    var booking = data.data();
                                    firestore.collection("Bookings").doc(bookingToApprouve).update({ status: "validate" }).then(() => {
                                        sendMail(booking.room, booking.startDate.toDate(), booking.endDate.toDate(), booking.price, "booking-accpet", i18n.language);
                                    });
                                    setBookingToApprouve("");
                                });
                            }}
                        >
                            {t("GLOBAL_CONFIRM")}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={selectedBooking !== null} onHide={() => setSelectedBooking(null)}>
                    <Modal.Header>
                        <h1>{t("DASHBOARD_ADMIN_BOOKING_DETAIL")}</h1>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedBooking !== null &&
                            <div>
                                <div className="row">
                                    <div className="col-sm">
                                        <p>{t("BOKING_ROOM")}</p>
                                    </div>
                                    <div className="col-sm">
                                        {selectedBooking?.room}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <p>{t("BOOKING_START_DATE")}</p>
                                    </div>
                                    <div className="col-sm">
                                        {formatDate(selectedBooking?.startDate)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <p>{t("BBOKING_END_DATE")}</p>
                                    </div>
                                    <div className="col-sm">
                                        {formatDate(selectedBooking?.endDate)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <p>{t("BOOKING_PRICE")}</p>
                                    </div>
                                    <div className="col-sm">
                                        {selectedBooking?.price} €
                                    </div>
                                </div>
                                <hr></hr>
                                <div className="row">
                                    <div className="col-sm">
                                        <p>{t("AUTH_FIRST_NAME")}</p>
                                    </div>
                                    <div className="col-sm">
                                        {selectedBooking?.user?.firstName}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <p>{t("AUTH_LAST_NAME")}</p>
                                    </div>
                                    <div className="col-sm">
                                        {selectedBooking?.user?.lastName}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <p>{t("AUTH_EMAIL")}</p>
                                    </div>
                                    <div className="col-sm">
                                        <a href={"mailto:" + selectedBooking?.user?.email}>{selectedBooking?.user?.email}</a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <p>{t("AUTH_PHONE_NUMBER")}</p>
                                    </div>
                                    <div className="col-sm">
                                        <a href={"tel:" + selectedBooking?.user?.phoneNumber}>{selectedBooking?.user?.phoneNumber}</a>
                                    </div>
                                </div>
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setSelectedBooking(null);
                            }}
                        >
                            {t("GLOBAL_CLOSE")}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={roomAddBooking !== ""} onHide={() => setRoomAddBooking("")}>
                    <Modal.Body>
                        <p>{t("DASHBOARD_ADMIN_ADD_BOOKING")}</p>
                        <div className="form-group">
                            <label className="label" for="check-in">
                                {t("BOOKING_DATE")}
                            </label>
                            <div className="form-dual form-dual--mobile">
                                <div className="form-dual__left">
                                    <DatePicker
                                        className="inputText inputText__icon readonly js-datepicker"
                                        dateFormat="dd-MM-yyyy"
                                        selected={startDate}
                                        onChange={(date) => selectStartDate(date)}
                                        customInput={
                                            <Form.Control
                                                className="inputText__icon"
                                                value={startDate}
                                            />
                                        }
                                    />
                                    <span className="input-icon icon-calendar"></span>
                                </div>
                                <div className="form-dual__right">
                                    <DatePicker
                                        className="inputText inputText__icon readonly js-datepicker"
                                        dateFormat="dd-MM-yyyy"
                                        selected={endDate}
                                        onChange={(date) => selectEndDate(date)}
                                        customInput={
                                            <Form.Control className="inputText__icon" value={endDate} />
                                        }
                                    />
                                    <span className="input-icon icon-calendar"></span>
                                </div>
                            </div>
                        </div>
                        {error && (
                            <Alert variant="danger">
                                <p>{error}</p>
                            </Alert>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setRoomAddBooking("");
                            }}
                        >
                            {t("GLOBAL_CLOSE")}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                addRoomBooking();
                                setRoomAddBooking("");
                            }}
                        >
                            {t("GLOBAL_CONFIRM")}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Tab.Container id="left-tabs-example" defaultActiveKey={roomState?.rooms[0]?.id}>
                    <Row>
                        <Col sm={3}>
                            <Nav variant="pills" className="flex-column">
                                {roomState?.bookings?.map(roomBookings => {
                                    return <Nav.Item key={roomBookings.room}>
                                        <Nav.Link eventKey={roomBookings.room}>{roomBookings.room}</Nav.Link>
                                    </Nav.Item>
                                })}
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                {roomState?.bookings?.map(roomBookings => {
                                    return <Tab.Pane key={roomBookings.room} eventKey={roomBookings.room}>
                                        <div className="float-right" style={{ marginBottom: "10px" }}>
                                            <Button
                                                className="btn btn-info"
                                                onClick={() => {
                                                    setRoomAddBooking(roomBookings.room)
                                                }}
                                            >
                                                {t("DASHBOARD_ADMIN_ADD_BOOKING")}
                                            </Button>
                                        </div>
                                        {roomBookings?.bookings?.length === 0 &&
                                            <p>{t("DASHBOARD_ADMIN_EMPTY_BOOKING")}</p>
                                        }
                                        {roomBookings?.bookings?.length > 0 &&
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>{t("BOOKING_START_DATE")}</th>
                                                        <th>{t("BBOKING_END_DATE")}</th>
                                                        <th>{t("BOOKING_PRICE")}</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {roomBookings.bookings.map((booking) => {
                                                        return (
                                                            <tr key={booking.id}>
                                                                <td>
                                                                    {booking.status === "blocked" && (
                                                                        <span className="badge badge-secondary">{t("BOOKING_STATUS_BLOCKED")}</span>
                                                                    )}
                                                                    {booking.status === "pending" && (
                                                                        <span className="badge badge-warning">{t("BOOKING_STATUS_PENDING")}</span>
                                                                    )}
                                                                    {booking.status === "validate" && (
                                                                        <span className="badge badge-success">{t("BOOKING_STATUS_VALIDATE")}</span>
                                                                    )}
                                                                </td>
                                                                <td>{formatDate(booking.startDate)}</td>
                                                                <td>{formatDate(booking.endDate)}</td>
                                                                <td>{booking.price} €</td>
                                                                <td>
                                                                    <div className="row">
                                                                        {booking.status !== "blocked" &&
                                                                            <div className="col-sm">
                                                                                <FaInfoCircle
                                                                                    onClick={() => {
                                                                                        selectBooking(booking);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        {booking.status !== "blocked" &&
                                                                            <div className="col-sm">
                                                                                <FaCheckCircle
                                                                                    onClick={() => {
                                                                                        setBookingToApprouve(booking.id);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        <div className="col-sm">
                                                                            <FaBan
                                                                                onClick={() => {
                                                                                    setBookingToDelete(booking.id);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        }
                                    </Tab.Pane>
                                })}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </main>
    );
};

export default Admin;
